<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-app
    id="main-app"
    :class="{hbl: isRoute(APP_ROUTES.hostedBuyersSurvey)}"
  >
    <!--<bar-->
    <!--  hide-drawer-icon-->
    <!--  show-toggle-theme-->
    <!--  :navigation.sync="navigation"-->
    <!--/>-->
    <app-snackbar
      v-if="AppSnackbar"
      class="d-print-none"
    />
    <!--<app-navigation-->
    <!--  v-model="navigation"-->
    <!--/>-->

    <slide-fade-transition>
      <v-main
        v-if="AppReady"
        id="main-content"
        class="main-container"
      >
        <v-container fluid>
          <v-row
            dense
            justify="space-between"
          >
            <v-col
              v-if="!isRoute(APP_ROUTES.homePage) && !isRoute(APP_ROUTES.newSurvey)"
              cols="auto"
            >
              <app-btn
                :class="isRoute(APP_ROUTES.hostedBuyersSurvey) ? 'white--text' : undefined"
                :color="isRoute(APP_ROUTES.hostedBuyersSurvey) ? 'blue' : undefined"
                :to="route(APP_ROUTES.homePage)"
              >
                <v-icon left>
                  home
                </v-icon>
                {{ $t('home') }}
              </app-btn>
            </v-col>
            <v-col cols="auto">
              <app-btn
                :class="isRoute(APP_ROUTES.hostedBuyersSurvey) ? 'blue--text' : undefined"
                icon
                @click="themeDark = !themeDark"
              >
                <v-icon>{{ `${ themeModeReverse }_mode` }}</v-icon>
              </app-btn>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <app-btn
                :class="isRoute(APP_ROUTES.hostedBuyersSurvey) ? 'white--text' : undefined"
                :color="isRoute(APP_ROUTES.hostedBuyersSurvey) ? 'blue' : undefined"
                @click="SetAppLocale(AppLocale === 'en' ? 'ar' : 'en',!0)"
              >
                {{ $t(AppLocale === 'en' ? 'ar' : 'en') }}
              </app-btn>
            </v-col>
          </v-row>
          <v-row
            dense
            justify="center"
          >
            <v-col cols="auto">
              <v-img
                v-if="AppSettings.expo_header_image"
                :src="AppSettings.expo_header_image"
                contain
                max-height="100%"
                max-width="1024"
                width="100%"
              />
            </v-col>
            <!--<app-progress-linear-->
            <!--  v-if="!AppIsSmall"-->
            <!--  class="d-print-none"-->
            <!--/>-->
          </v-row>
          <!--<v-row no-gutters>-->
          <!--  <v-col cols="12">-->
          <!--  </v-col>-->
          <!--</v-row>-->
        </v-container>
        <slide-fade-transition>
          <layout-router-view v-if="AppReady" />
        </slide-fade-transition>
        <slide-fade-transition>
          <slot v-if="AppReady" />
        </slide-fade-transition>
      </v-main>
    </slide-fade-transition>

    <!--<app-main-footer-->
    <!--  v-if="AppReady && !AppIsSmall"-->
    <!--  class="d-print-none"-->
    <!--/>-->
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import LayoutRouterView from '@/layouts/LayoutRouterView'
import { MetaInfoMixin } from '@mixins'
import SlideFadeTransition from '@components/SlideFadeTransition'
import Bar from '@components/base/app/Bar'

export default {
  name: 'LandingLayout',
  components: { Bar, SlideFadeTransition, LayoutRouterView },
  mixins: [MetaInfoMixin],
  data () {
    return {
      navigation: this.$vuetify.breakpoint.mdAndUp,
      // navigation: !0,
      bottomNavigation: null
    }
  },
  computed: {
    bottomNavigationButtons () {
      return [
        {
          text: 'home',
          icon: 'mdi-home',
          value: this.APP_ROUTES.homePage,
          to: this.route(this.APP_ROUTES.homePage)
        },
        // {
        //   text: '',
        //   icon: 'mdi-book',
        //   value: this.APP_ROUTES..index,
        //   to: this.route(this.APP_ROUTES..index)
        // },
        {
          text: 'profilePage.name',
          icon: 'mdi-account',
          value: this.APP_ROUTES.user.profile,
          to: this.route(this.APP_ROUTES.user.profile)
        },
        {
          text: 'notifications',
          icon: 'mdi-bell',
          value: this.APP_ROUTES.user.notifications,
          badge: this.APP_ROUTES.user.notifications,
          to: this.route(this.APP_ROUTES.user.notifications)
        }
      ]
    },
    AppSnackbar: {
      set (value) {
        mapMutations('snackbar', ['setShow']).setShow.call(this, value)
      },
      get () {
        return mapGetters('snackbar', ['getShow']).getShow.call(this)
      }
    }
  },
  watch: {
    // $route: {
    //   deep: !0,
    //   immediate: !0,
    //   handler: 'checkAuthDialog'
    // },
  },
  mounted () {
  },
  methods: {}
}
</script>
<style scoped>
@media print {
  .main-container {
    background: none !important;
  }

  .v-main {
    /*padding-top: 0 !important;*/
    padding: 0 !important;
  }
}

.main-container > .v-main__wrap > .container {
  max-width: 1024px;
}

.main-container {
  /*max-width: 1024px;*/
  /*min-height: 90vh;*/
  /*background: url(/assets/images/bg/artwork.png);*/
  /*background-position: -125px -125px;*/
  /*padding-bottom: 50px;*/
}

.main-container > .v-main__wrap {
  /*padding-bottom: 50px;*/
}
</style>
